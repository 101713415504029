import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import AccountDataTabs from './accountDataTabs/AccountDataTabs.vue';
import programListStore from "@/store/modules/programList";
import APP_CONST from '@/constants/AppConst';
import { AccountDetails, AccountDtById } from '@/Model/model.js';
import { getModule } from 'vuex-module-decorators';
import { GlobalModule } from '@/store/global/globalModule';
import APP_UTILITIES from '@/utilities/commonFunctions';
import store from '@/store';
import headerStore from '@/store/modules/headerStore';
import BreadcrumbComponent from '@/commoncomponents/breadcrumbComponent/BreadcrumbComponent.vue';
import MobileScreenUnavailable from '@/commoncomponents/mobileScreenUnavailable/MobileScreenUnavailable.vue';
import DeviceWidthCheckerMixin from '@/mixins/deviceWidthChecker.mixin';

@Component({
    components: {
        'account-tabs': AccountDataTabs,
        'bread-crumb': BreadcrumbComponent,
        'mobile-unavailable-screen': MobileScreenUnavailable,
    }
})
export default class AccountDetailsComponent extends Mixins(DeviceWidthCheckerMixin) {
    public search: string = '';
    public accountDt: AccountDetails = {} as AccountDetails;
    getGlobalState = getModule(GlobalModule, store)
    public roleId: number = -1;
    accountBreadCrumbs: string[] = ['Partner Accounts','Account'];

    @Prop()
    userDetails!: any;

    @Prop()
    signalRStrip!:{component:string,stripShow:boolean,stripText:string}

    /* istanbul ignore next */
    get primaryAccountAdmin() {
        let primaryAccountAdmin: any = programListStore.accountDetails.data && programListStore.accountDetails.data.accountUsers && programListStore.accountDetails.data.accountUsers.filter((user: any) => {
            return user.isPrimaryAccountAdmin
        });
        return primaryAccountAdmin && primaryAccountAdmin.length ? primaryAccountAdmin[0] : ''

    }


    /* istanbul ignore next */
    beforeMount() {
        let dlObject = {
            "AccountName": undefined,
            "AccountType": undefined,
            "UserRole": 0,
            "ProgramName": undefined,
            "SiteName": undefined
        }
        this.$emit('updateDataLayer', dlObject)
        this.accountDt = {} as AccountDetails;
        const id = APP_UTILITIES.getCookie('accountId');
        /* istanbul ignore else */
        if (this.userDetails && !this.userDetails.accountID && !id) {
            this.$router.push('/home');
        }
        const accountId = this.userDetails && this.userDetails.accountID;
        let idx = this.getGlobalState.accountId;
        let storedAccountId: any = APP_UTILITIES.getCookie('accountId');
        const payload = APP_CONST.PROGRAM_PAYLOAD;
        payload.id = parseInt(storedAccountId);
        /* istanbul ignore else */
        if (payload != undefined) {
            idx = payload.id;
        }
        /* istanbul ignore else */
        if (accountId || id) {
            this.accountById({ accountId: idx })
        }
        const { roleId } = APP_UTILITIES.coreids();
        this.roleId = roleId;
    }

    hideOrangeBar(){
        /* istanbul ignore else */
        if(localStorage.getItem('stripKey')){
            Vue.prototype.$eventHub.$emit(APP_CONST.SEND_INVITATION_STRIP_INDICATOR, {
                component: APP_CONST.BLANK,
                stripShow: APP_CONST.FALSE,
                sendInvitationStripShow: APP_CONST.FALSE,
            });
        }

        if(this.signalRStrip && this.signalRStrip.component == APP_CONST.RESET_PASSWORD){
            Vue.prototype.$eventHub.$emit(APP_CONST.RESET_PASSWORD_INDICATOR_STRIP, {
                component: APP_CONST.RESET_PASSWORD,
                stripShow: false,
                sendInvitationStripShow: false,
            });
        }
    }

    hidePrivelegeUndo(){
        Vue.prototype.$eventHub.$emit("showAttendanceUndoStripIndicater", {
            component: "bulkAddPrivileges",
            stripShow: false,
            attendanceStripShow: false,
        });
    }

    navigateToAccountAdmin() {
        /* istanbul ignore else */
        if (this.roleId == APP_CONST.ROLE_TYPE_SUPER_ADMIN || this.roleId == APP_CONST.ROLE_TYPE_BX_ADMIN) {
            let userRoles: any = APP_UTILITIES.getCookie("user_role");
            let userData = JSON.parse(userRoles);
            userData[0].roleId = APP_CONST.ACCOUNT_ADMIN_ROLE_ID;
            userData[0].accountId = this.accountDt.accountId;
            APP_UTILITIES.setCookie("super_user_drilldown", 'true', 1);
            APP_UTILITIES.setCookie("super_user_drilldown_to_account", 'true', 1);
            APP_UTILITIES.setCookie("user_role", JSON.stringify(userData), 1);
            APP_UTILITIES.setCookie("accountName", this.accountDt.accountName, 1);
            headerStore.mutateBxState(true);
            this.$router && this.$router.push('/partnerhome');
            this.$emit('setIndicationData');
            this.$emit('loadNav', {});
        }
    }

    async accountById(payload: AccountDtById) {
        await programListStore.accountById(payload);
        /* istanbul ignore else */
        if (programListStore.accountDetails.status === APP_CONST.RESPONSE_200) {
            this.accountDt = { ...programListStore.accountDetails.data };
            let dlObject = { 'AccountName': this.accountDt.accountName, 'AccountType': this.accountDt.type, 'UserRole': this.userDetails.roleId }
            this.$emit('updateDataLayer', dlObject)
        } else {
            this.accountDt = {} as AccountDetails;
        }
    }

    getAccountStatus(status: number) {
        let statusArray = ['', 'Active', 'Inactive'];
        return statusArray[status];
    }

    getColorCode(email: string) {
        return APP_UTILITIES.getColorCode(email)
    }

    getInitialLater(firstName: string, lastName: string) {
        return APP_UTILITIES.getInitialLetters(firstName, lastName);
    }

    editAccount() {
        this.$router && this.$router.push('/accounts/edit');
    }

    loadNav(){
        this.$emit('loadNav', {});
    }

    formatDate(date: string){
        return date && APP_UTILITIES.formatShortDate(date);
    }
}
